<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'
  export let direction = 'down'

  export let ariaLabel = 'chevron'

  $: fillColor = colors[color] || color
</script>

<style>
  .up {
    transform: rotate(180deg);
  }

  .left {
    transform: rotate(90deg);
  }

  .right {
    transform: rotate(270deg);
  }
</style>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 -4.5 24 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
  class:up={direction === 'up'}
  class:left={direction === 'left'}
  class:right={direction === 'right'}
>
  <g stroke="none" stroke-width="1" fill-rule="evenodd">
    <g transform="translate(-574.000000, -1201.000000)">
      <path
        d="M597.405,1201.63 C596.576,1200.8 595.23,1200.8 594.401,1201.63 L586.016,1210.88 L577.63,1201.63 C576.801,1200.8 575.455,1200.8 574.626,1201.63 C573.797,1202.46 573.797,1203.81 574.626,1204.64 L584.381,1215.4 C584.83,1215.85 585.429,1216.05 586.016,1216.01 C586.603,1216.05 587.201,1215.85 587.65,1215.4 L597.405,1204.64 C598.234,1203.81 598.234,1202.46 597.405,1201.63"
      >
      </path>
    </g>
  </g>
</svg>
